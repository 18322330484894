import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns mdxType="Columns">
      <h5>{`Willkommen bei den Maschinen-Flüsterern!`}</h5>
      <p>{`Wir verstehen sowohl die Maschinen als auch die IT-Systeme unserer Kunden. Und lassen beide effektiv miteinander kommunizieren.`}</p>
      <h2>{`Wir bieten:`}</h2>
      <ul>
        <li parentName="ul">{`technische Gesamtkonzeption`}</li>
        <li parentName="ul">{`Spezifikation geeigneter Schnittstellen mit beteiligten Herstellern`}</li>
        <li parentName="ul">{`Implentierung der Kommunikation mit Datenbanken oder Serversystemen`}</li>
        <li parentName="ul">{`Datenaufbereitung nach Vorgaben des Auftraggebers`}</li>
        <li parentName="ul">{`Benutzerinterfaces zur Steuerung und Überwachung des Produktionsprozesses`}</li>
        <li parentName="ul">{`Konstante Überwachung und Auswertung der Maschinenansteuerung`}</li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      